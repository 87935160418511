import { render, staticRenderFns } from "./shipmentRecordDetails.vue?vue&type=template&id=4ba6bc04&scoped=true&"
import script from "./shipmentRecordDetails.vue?vue&type=script&lang=js&"
export * from "./shipmentRecordDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ba6bc04",
  null
  
)

export default component.exports