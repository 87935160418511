<template>
  <div class="container_box details_box_styles">
    <!--返回按钮-->
    <div class="go_back" @click="goBackBtn">
      <Icon class="back_icon" type="ios-arrow-back"/>
      <span class="back_txt">{{ $t('key1001940') }}</span>
    </div>
    <!--基本信息-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title><h2 class="title_box">{{ $t('key1000645') }}</h2></template>
        <Row type="flex" :gutter="gutterItem">
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1003335') }}</span>
            <span class="basic_txt">{{ detailsInfo.pickupOrderNumber }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1003345') }}</span>
            <span class="basic_txt">{{ detailsInfo.thirdPickupOrderNumber }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1001133') }}</span>
            <span class="basic_txt">{{ detailsInfo.statusText }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1000920') }}</span>
            <span class="basic_txt">{{ detailsInfo.createdTime }}</span>
          </Col>
          <Col :span="ItemCol" class="basic_item">
            <span class="basic_title">{{ $t('key1003279') }}</span>
            <span class="basic_txt">{{ detailsInfo.pickupTime }}</span>
          </Col>
        </Row>
      </Card>
    </div>
    <!--提单申明数量及实际到仓数量-->
    <div class="basic_information_box">
      <Card :bordered="false" dis-hover>
        <template #title>
          <h2 class="title_box">{{ $t('key1003346') }}</h2>
        </template>
        <template v-if="saleOrderData.length >0">
          <h3 class="mb10 font-size-14">{{ $t('key1003347') }}</h3>
          <div class="table_box">
            <Table
              border
              max-height="400"
              :columns="tableColumns"
              :data="saleOrderData">
            </Table>
            <Page
              :total="saleOrderTotal"
              @on-change="saleOrderChangePage"
              show-total
              :page-size="saleOrderPageParams.pageSize"
              show-elevator
              :current="saleOrderPageParams.pageNum"
              show-sizer
              @on-page-size-change="saleOrderChangePageSize"
              placement="top"
              :page-size-opts="pageArray">
            </Page>
          </div>
        </template>
        <template v-if="stockListData.length >0">
          <h3 class="mb10 mt30 font-size-14">{{ $t('key1003348') }}</h3>
          <div class="table_box">
            <Table
              border
              max-height="400"
              :columns="tableColumns"
              :data="stockListData">
            </Table>
            <Page
              :total="stockTotal"
              @on-change="stockChangePage"
              show-total
              :page-size="stockPageParams.pageSize"
              show-elevator
              :current="stockPageParams.pageNum"
              show-sizer
              @on-page-size-change="stockChangePageSize"
              placement="top"
              :page-size-opts="pageArray">
            </Page>
          </div>
        </template>
        <template v-if="shipmentsQuantityData.length >0">
          <h3 class="mb10 mt30 font-size-14">
            <span>{{ $t('key1003349') }}</span>
            <Tooltip max-width="400" placement="top" class="ml5 cursor" transfer
              :content="$t('key1003342')+`${configCharacters}`+$t('key1003343')">
              <Icon size="17" type="md-help-circle"/>
            </Tooltip>
          </h3>
          <Table
            border
            max-height="400"
            :columns="tableColumns"
            :data="shipmentsQuantityData">
          </Table>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import { alias47916751af154eb5b47cd5de9d34633d } from '@/customFolder/customVueAlias.js';

import Mixin from "@/components/mixin/common_mixin";
import api from '@/api/';
import {commonCopyContent, setConfigCharacters} from "@/utils/common";

export default {
  name: "shipmentRecordDetails",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      ItemCol: 6,
      gutterItem: 16, // 栅格间距，单位 px，左右平分
      detailsInfo: {},
      tableColumns: [
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000445'),
          key: 'packageCode',
          align: 'left',
          minWidth: 130,
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1003351'),
          key: 'warehouseName',
          align: 'left',
          minWidth: 130,
          render(h, params) {
            return h('span', v.getWarehouseName(params.row.ymsWarehouseId));
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000315'),
          minWidth: 320,
          align: 'left',
          render: (h, params) => {
            if (params.row._productInfo) {
              let imgPath = '';
              let skuImgPath = params.row._productInfo.primaryImage;
              let spuImgPath = params.row._productInfo._spuInfo.primaryImage
              imgPath = skuImgPath ? skuImgPath : spuImgPath;
              let productGoodsSpecifications = params.row._productInfo.productGoodsSpecifications;
              let spec = productGoodsSpecifications ? productGoodsSpecifications.map(i => i.name + '：' + i.value).join('、') : '';
              let supplierSku = params.row._productInfo.sku || ''; // 供应商sku
              let ymsSku = params.row._productInfo.ymsSku || ''; // 云卖sku
              return h('div', {
                class: 'flex mt5'
              }, [
                v.tableImg(h, params, null, imgPath),
                h('div', {class: 'ml10 flex flex-direction'}, [
                  h('Alink', {
                    props: {
                      linkName: params.row._productInfo._spuInfo.name,
                      textAlign: 'left',
                    },
                    on: {
                      AlinkBtn: () => {
                        const {href} = v.$router.resolve({
                          path: '/productDetails',
                          query: {
                            type: 'look',
                            productId: params.row._productInfo.productId
                          }
                        });
                        window.open(href, '_blank');
                      }
                    }
                  }),
                  h('p', {class: 'flex align-items-center mt5'}, [
                    h('span', alias47916751af154eb5b47cd5de9d34633d.t('key1003337') + supplierSku),
                    h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      class: 'ml10 cursor',
                      attrs: {
                        title: alias47916751af154eb5b47cd5de9d34633d.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(supplierSku, alias47916751af154eb5b47cd5de9d34633d.t('key1000979'));
                        }
                      }
                    })
                  ]),
                  h('p', {class: 'flex align-items-center mt5'}, [
                    h('span', `${setConfigCharacters}SKU：` + ymsSku),
                    h('Icon', {
                      props: {
                        type: 'ios-copy-outline',
                        size: 18,
                        color: '#01a6ee'
                      },
                      class: 'ml10 cursor',
                      attrs: {
                        title: alias47916751af154eb5b47cd5de9d34633d.t('key1000000')
                      },
                      on: {
                        click: () => {
                          commonCopyContent(ymsSku, alias47916751af154eb5b47cd5de9d34633d.t('key1000979'));
                        }
                      }
                    })
                  ]),
                  h('p', {class: 'mt5 mb5'}, alias47916751af154eb5b47cd5de9d34633d.t('key1000207') + spec)
                ])
              ])
            }
          }
        },
        {
          title: alias47916751af154eb5b47cd5de9d34633d.t('key1000446'),
          key: 'quantity',
          minWidth: 130,
          align: 'left',
          render(h, params) {
            return h('p', params.row.quantity)
          }
        },
        {
          title: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1003350')}`,
          key: 'actualSortingNumber',
          align: 'left',
          minWidth: 160,
          render(h, params) {
            let quantity = params.row.quantity;
            let actualSortingNumber = params.row.actualSortingNumber;
            // 已收货
            if (v.detailsInfo.status === 3) {
              return h('Alink', {
                props: {
                  AlinkShow: false,
                  linkName: actualSortingNumber,
                  textAlign: 'left',
                  styles: {
                    color: actualSortingNumber !== quantity ? '#D9001B' : '#333'
                  }
                },
              })
            } else {
              return h('span', '-');
            }
          }
        },
      ],
      saleOrderData: [], // 销售订单
      stockListData: [], // 备货单
      shipmentsQuantityData: [], // 多发货品数量
      saleOrderTotal: 0,
      saleOrderPageParams: {
        wmsPickupOrderId: v.$route.query.wmsPickupOrderId,
        outboundTypes: [1, 5],
        pageNum: 1,
        pageSize: 15,
      },
      stockTotal: 0,
      stockPageParams: {
        wmsPickupOrderId: v.$route.query.wmsPickupOrderId,
        outboundTypes: [2, 3],
        pageNum: 1,
        pageSize: 15,
      },
    }
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    }
  },
  created() {
    this.initFun();
  },
  methods: {
    // 返回列表
    goBackBtn() {
      this.$router.push('/shipmentRecord');
    },
    // 初始化数据
    initFun() {
      let v = this;
      (async () => {
        await v.getBelongingWarehouseData(v.userInfo.merchantId).then((data) => {
          if (data && data.length > 0) {
            v.warehouseList = data;
          }
        });
        await v.getUserName();
        await v.getPickupOrderData(v.$route.query.pickupOrderNumber);
        await v.getSaleOrderData();
        await v.getStockListData();
      })();
    },
    // 获取查看装箱详情的数据
    getPickupOrderData(pickupOrderNumber) {
      let v = this;
      v.tableLoading = true;
      let newList = [];
      v.shipmentsQuantityData = [];
      v.axios.get(api.get_wmsPickupOrder_wmsPickupOrderDetail + `${pickupOrderNumber}`).then(response => {
        v.tableLoading = false;
        if (response.data.code === 0) {
          setTimeout(() => {
            v.$store.commit('updateSystemMenu', false);
          }, 2000);
          let data = response.data.datas;
          let statusList = [
            {value: 0, title: alias47916751af154eb5b47cd5de9d34633d.t('key1005721')},
            {value: 1, title: alias47916751af154eb5b47cd5de9d34633d.t('key1001287')},
            {value: 2, title: alias47916751af154eb5b47cd5de9d34633d.t('key1001261')},
            {value: 3, title: `${setConfigCharacters}${alias47916751af154eb5b47cd5de9d34633d.t('key1003691')}`},
            {value: 99, title: alias47916751af154eb5b47cd5de9d34633d.t('key1001290')},
          ];
          statusList.map((item) => {
            if (item.value === data.status) {
              data.statusText = item.title;
            }
          });
          data.createdTime = v.$uDate.getDataToLocalTime(data.createdTime, 'fulltime');
          data.pickupTime = v.$uDate.getDataToLocalTime(data.pickupTime, 'fulltime');
          v.detailsInfo = data;
          let wmsPickupOrderDetails = data.wmsPickupOrderDetails || [];
          if (wmsPickupOrderDetails.length > 0) {
            wmsPickupOrderDetails.map((item, idx) => {
              let packageGoods = item.packageGoods || [];
              if (item.wmsPickGoodsBo && item.wmsPickGoodsBo.goodsDetailBos) {
                item.wmsPickGoodsBo.goodsDetailBos.map((ele, index) => {
                  let length = item.wmsPickGoodsBo.goodsDetailBos.length;
                  ele['outboundType'] = item.outboundType;
                  ele['length'] = length;
                  ele['currentTalg'] = (length > 1 && index === 0);
                  ele['packageCode'] = item.packageCode;
                  ele['trackingNumber'] = item.trackingNumber;
                  ele['wmsPickupOrderDetailId'] = item.wmsPickupOrderDetailId;
                  ele['path'] = ele.productGoodsImages ? ele.productGoodsImages[0].path : '';
                  ele['valueList'] = [];
                  if (ele.productGoodsSpecifications && ele.productGoodsSpecifications.length > 0) {
                    ele.productGoodsSpecifications.map((talg) => {
                      ele['valueList'].push(talg.value);
                    });
                    ele['valueList'] = v.uniqueFunc(ele['valueList']);
                    ele['ymsWarehouseId'] = item.ymsWarehouseId;
                    if (packageGoods.length > 0) {
                      packageGoods.map((talg) => {
                        if (talg.productGoodsId === ele.productGoodsId) {
                          ele['actualSortingNumber'] = talg.actualSortingNumber
                        }
                      })
                    }
                  }
                  newList.push(ele);
                });
              }
            });
            if (newList.length > 0) {
              let productGoodsIds = [];
              newList.map((item, index) => {
                item['tableLength'] = newList.length;
                if (item.currentTalg) {
                  item['currentIndex'] = index;
                }
                productGoodsIds.push(item.productGoodsId);
              });
              let list = v.uniqueFunc(productGoodsIds);
              if (list.length > 0) {
                (async () => {
                  let newData = await v.getProductInfoByIds(list, newList,
                    false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
                  if (newData.length > 0) {
                    // 多发货品数量
                    v.shipmentsQuantityData = newData.filter((item) => {
                      return item.outboundType === 4;
                    });
                  }
                })();
              }
            }
          }
        }
      });
    },
    // 获取销售订单数据
    getSaleOrderData() {
      let v = this;
      v.saleOrderData = [];
      let productGoodsIds = [];
      v.axios.post(api.post_wmsPickupOrderDetail_queryByPackageInfoToGoods, v.saleOrderPageParams).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.list || [];
            v.saleOrderTotal = data.total;
            if (list.length > 0) {
              list.map((item) => {
                productGoodsIds.push(item.productGoodsId);
              });
            }
            if (productGoodsIds.length > 0) {
              (async () => {
                v.saleOrderData = await v.getProductInfoByIds(productGoodsIds, list,
                  false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
              })();
            }
          }
        }
      });
    },
    // 获取备货单的数据
    getStockListData() {
      let v = this;
      v.stockListData = [];
      let productGoodsIds = [];
      v.axios.post(api.post_wmsPickupOrderDetail_queryByPackageInfoToGoods, v.stockPageParams).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          if (data) {
            let list = data.list || [];
            v.stockTotal = data.total;
            if (list.length > 0) {
              list.map((item) => {
                productGoodsIds.push(item.productGoodsId);
              });
            }
            if (productGoodsIds.length > 0) {
              (async () => {
                v.stockListData = await v.getProductInfoByIds(productGoodsIds, list,
                  false, false, false, api.post_productInfo_queryByProductGoodsIds, 'supplier');
              })();
            }
          }
        }
      });
    },
    saleOrderChangePage(page) {
      let v = this;
      v.saleOrderPageParams.pageNum = page;
      v.getSaleOrderData();
    },
    saleOrderChangePageSize(pageSize) {
      let v = this;
      v.saleOrderPageParams.pageSize = pageSize;
      v.getSaleOrderData();
    },

    stockChangePage(page) {
      let v = this;
      v.stockPageParams.pageNum = page;
      v.getStockListData();
    },
    stockChangePageSize(pageSize) {
      let v = this;
      v.stockPageParams.pageSize = pageSize;
      v.getStockListData();
    },
    // 合并单元格
    /*handleSpan({row, column, rowIndex, columnIndex}) {
      let columnList = [0, 1];
      if (columnList.includes(columnIndex)) {
        if (row.length > 1) {
          if (rowIndex === row.currentIndex) {
            return [row.length, 1]
          } else {
            for (let i = 0; i <= row.tableLength; i++) {
              if (rowIndex === i) {
                return [0, 0]
              }
            }
          }
        }
      }
    },*/
  }
}
</script>

<style lang="less" scoped>

</style>
